import { CropSquare, Home, Movie, Palette } from "@mui/icons-material";

export interface AppsListItem {
    title?: string,
    note?: string,
    href?: string,
    icon?: any,
    divider?: boolean,
};

export const AppsList: AppsListItem[] = [
    {
        title: "Wide Video",
        note: "Record wide videos",
        href: "https://wide-video.surge.sh/",
        icon: <Movie />,
    },
    {
        title: "Canvas Video",
        note: "Record to webm or mp4 dep on browser",
        href: "https://canvas-video.surge.sh/",
        icon: <Movie />,
    },
    {
        title: "SVG Glow Lines",
        note: "Glow effect lost when imported",
        href: "https://svg-glowlines.surge.sh/",
        icon: <Palette />,
    },
    {
        title: "PNG Chrome Lines",
        note: "Save direct to PNG",
        href: "https://png-chromelines.surge.sh/",
        icon: <Palette />,
    },
    {
        title: "PNG Lines",
        note: "Save direct to PNG",
        href: "https://png-lines.surge.sh/",
        icon: <Palette />,
    },
    {
        title: "SVG Lines",
        href: "https://svg-lines.surge.sh/",
        icon: <Palette />,
    },
    {
        title: "SVG Render",
        href: "https://svg-render.surge.sh/",
        icon: <Palette />,
    },
    {
        title: "SVG Maze Generator",
        href: "https://maze-gen.surge.sh/",
        icon: <CropSquare />,
    },
    {
        divider: true,
    },
    {
        title: "MitchAllen.com",
        href: "https://mitchallen.com/",
        icon: <Home />,
    },
];